import Footer from "../../components/footer";

const TermsAndConditions = (): JSX.Element => {
  return (
    <>
      <main className="flex flex-wrap grow mt-12 xl:mt-16 px-4 pb-10 lg:px-8 transition-all duration-300 page-enter:opacity-0 page-enter:-translate-y-4 layout-enter:opacity-0 layout-enter:-translate-y-4 pt-16">
        <div className="w-full mx-auto max-w-5xl 5xl:container">
          <div className="prose prose-invert w-full max-w-none textPage" >
            <div>
              <h1>TERMS AND CONDITIONS</h1>
              <p>These Terms and Conditions form a binding legal agreement between You and Us and apply to your use of our Platform, Website, or games in any way, through any electronic device (web, mobile, tablet, or any other device).</p>
              <p>Please note that these Terms and Conditions include a provision waiving the right to pursue any class, group, or representative claim and requiring You to pursue past, pending, and future disputes between You and Us through individual arbitration unless You opt out within the specified time frame.</p>
              <p>You must read these Terms and Conditions carefully in their entirety before checking the box for acceptance. By checking the box for acceptance during the registration process, or by accessing the Games or the Platform, you confirm that you have read and agree to be bound by these Terms and Conditions, which include and are inseparably linked to our Privacy Policy and other promotion-specific or game-specific terms relevant to your participation. If you do not agree with any provision of these Terms and Conditions or any other linked policy, rules or terms you may not install or use the Platform.</p>
              <h2 id='1-definitions'>1. DEFINITIONS</h2>
              <p>1.0. <strong>Collective Action</strong> means any claim as part of a class, group, collective, coordinated, consolidated, mass, or representative proceeding, whether by arbitration or in court.</p>
              <p>1.1. <strong>Content</strong> – means text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork, computer code and other material used, displayed or available as part of the Games and Platform.</p>
              <p>1.2. <strong>Customer Account</strong> – means an account held by a Registered Customer.</p>
              <p>1.3. <strong>&quot;Excluded Jurisdiction(s)&quot; means:</strong></p>
              <p>1.3.0. Afghanistan, Algeria, Angola, Belarus, Burma, Central African Republic, Cuba, Democratic Republic of the Congo, Eritrea, Haiti, Holy See, Iran, Iraq, Kyrgystan, Lebanon, Libya, North Korea, Palestine State, Republic of Ireland, Rwanda, Sierra Leone, Somalia, South Sudan, Sudan, Syria, Venezuela, Yemen, Zimbabwe and the American states of Nevada (USA), Washington (USA), Georgia (USA), Minnesota (USA), Mississippi (USA), Montana (USA), South Dakota (USA) and any other countries, territories or jurisdictions in which it would be illegal to play Games or redeem Prizes.</p>
              <p>1.4. <strong>Fraudulent Conduct</strong>– means any of the conduct described in clause 18.</p>
              <p>1.5. <strong>Game(s)</strong> – means any one or more WinBig Game(s) available on the Platform. We reserve the right to add and remove Games from the Platform at our sole discretion.</p>
              <p>1.6. <strong>Inactive Account</strong> means a Customer Account that has not recorded any login in or log out for a period exceeding 12 consecutive months.</p>
              <p>1.7. <strong>Participate</strong> means playing any Games or using our Platform in any manner whatsoever, including any of the conduct described in clause 2.</p>
              <p>1.8. <strong>Payment Administration Agent</strong> means any related body corporate, affiliate, or the third party we appoint to act as our agent, including but not limited to WinBig Limited</p>
              <p>1.9. <strong>Platform</strong> means any URL or mobile application belonging to, or licensed to, WinBig Limited and branded as part of the “WinBig” family of games, including the website located at <a href='https://www.WinBig.space' target='_blank' rel="noreferrer">https://www.WinBig.space</a>, and all subdomains, subpages and successor sites thereof, as well as all games, features, tools and services available thereon.</p>
              <p>1.10. <strong>Player</strong> or <strong>You, you, your or Your</strong> means any person who Participates, whether or not a Registered Customer.</p>
              <p>1.11. <strong>Registered Customer</strong> means a Player who has successfully registered a Customer Account, whether that account is considered active or not.</p>
              <p>1.12. <strong>Service</strong> means the availability and provision of the Games and the Website that enable you to Participate.</p>
              <p>1.13. <strong>Terms and Conditions</strong> mean these terms and conditions, as amended from time to time.</p>
              <p>1.14. <strong>Third-Party Websites</strong> means a third-party website not controlled by us.</p>
              <p>1.15. <strong>WinBig Games</strong> means any game played.</p>
              <p>1.16. <strong>WinBig Limited</strong> means Pow Lab Ltd , having its registered address at Trust Company Complex, Ajeltake Road, Ajeltake Island, Majuro, Republic of the Marshall Island MH 96960</p>
              <p>1.17. <strong>WinBig Limited, We, we, Us, Our or our</strong> means WinBig Limited</p>
              <p>1.18. <strong>Website</strong> means the website located at <a href='https://WinBig.space' target='_blank' rel="noreferrer">https://WinBig.space</a>, any other related sites operated by Us, and all subdomains, subpages, and successor sites thereof.</p>
              <h2 id='2-your-participation'>2. YOUR PARTICIPATION</h2>
              <h4 id='20-restrictions'>2.0. <strong>Restrictions</strong></h4>
              <p>You hereby declare and warrant that:</p>
              <p>2.0.0. You are over 18 years of age or the higher minimum legal age of majority as stipulated in the jurisdiction of your residence and are, under the laws applicable to you, legally allowed to participate in the Games offered on the Platform;</p>
              <p>2.0.1. You participate in the Games strictly in your personal capacity for recreational and entertainment purposes only;</p>
              <p>2.0.2. You participate in the Games on your own behalf and not on the behalf of any other person;</p>
              <p>2.0.3. all information that You provide to Us during the validity of these Terms and Conditions is true, complete, and correct, and you will immediately notify Us of any change to such information;</p>
              <p>2.0.4. money that You use to play WinBig games is not tainted with any illegality and, in particular, does not originate from any illegal activity or source;</p>
              <p>2.0.5. You will not play WinBig games from a business or corporate account, but only from an account held in your name.</p>
              <p>2.0.6. You will not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to your or third parties&#39; participation in any of the Games and you will not use any software-assisted methods or techniques (including but not limited to bots designed to play automatically) or hardware devices for your participation in any of the Games. We reserve the right to invalidate any participation in the event of such behavior.</p>
              <p>2.1. It is a Player’s responsibility to ensure that their Participation is lawful in their jurisdiction. Any person who is knowingly in breach of clause 2., including any attempt to circumvent this restriction, for example, by using a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your location or place of residence, or by Participating through aFF third party or on behalf of a third party located in a jurisdiction where it is unlawful to Participate, is in breach of these Terms and Conditions. You may commit fraud and may be subject to criminal prosecution.</p>
              <p>2.2. Employees of WinBig, any of their respective affiliates, subsidiaries, parent or holding companies, advertising agencies, or any other company or individual involved with the design, production, execution or distribution of the Games and their immediate family (spouse, parents, siblings and children, whether the relationship is by birth, marriage or adoption) and household members (people who share the same residence at least 6 months of the year) are not eligible to participate.</p>
              <p>2.3. By accepting these Terms and Conditions You agree that your Participation is at your sole option, discretion, and risk. You will have no claims whatsoever against WinBig Limited or any of its partners, or respective directors, officers or employees in relation to any losses You incur.</p>
              <h2 id='3-licence'>3. LICENCE</h2>
              <p>3.0. Subject to your agreement and continuing compliance with these Terms and Conditions, WinBig Limited grants You a personal, non-exclusive, non-transferable, non-sublicensable, revocable, limited licence to access and use the Platform, through a supported Web browser or mobile device, solely for your personal, private entertainment and no other reason.</p>
              <p>3.1. These Terms and Conditions do not grant You any right, title or interest in the Platform or Content.</p>
              <p>3.2. You acknowledge and agree that your licence to use the Platform is limited by these Terms and Conditions and if you do not agree to, or act in contravention of, these Terms and Conditions, your licence to use the Platform (including the Games and Content) may be immediately terminated.</p>
              <p>3.3. Where the Platform or any Game is deemed to be illegal under the laws of the jurisdiction in which you reside or are situated, you will not be granted any licence to, and must refrain from accessing, the Platform or relevant Game.</p>
              <h2 id='4-your-customer-account'>4. YOUR CUSTOMER ACCOUNT</h2>
              <h4 id='40-single-account'>4.0. <strong>Single Account</strong></h4>
              <p>You are allowed to have only one Customer Account per household, including any Inactive Accounts, on the Platform. If you attempt to open more than one Customer Account, all accounts you have opened or tried to open may be cancelled or suspended and the consequences are described in clause 16. may be enforced:</p>
              <p>4.0.0. You must notify us immediately if you notice that you have more than one registered Customer Account, whether active or not, on the Platform.</p>
              <p>4.0.1. We store your full name, and date of birth.</p>
              <h4 id='41-accuracy'>4.1. <strong>Accuracy</strong></h4>
              <p>You are required to always keep your registration details up to date. If you update any of your personal information, contact Customer Support via email at feedback@winbig.space in order to update your details. If provided, the name that you used at registration must be identical to that listed on your government-issued identification. Please note that any changes to your personal details may result in you having to submit identification in order to verify your identity.</p>
              <h4 id='42-security-and-responsibility-of-your-customer-account'>4.2. <strong>Security and Responsibility of Your Customer Account</strong></h4>
              <p>As part of the registration process, you will have to choose a password to login into the Platform, if offered, you may log in to your Customer Account using MetaMask. It is your sole and exclusive responsibility to ensure that your Customer Account login details and any payment methods are kept secure and are only accessible to you. You accept full responsibility for any unauthorized use of your Customer Account and any activity linked to your Customer Account, including by a minor (which in all events is prohibited).</p>
              <p>4.2.0. You must not share your Customer Account or password with another person, let anyone else access or use your Customer Account or do any other thing that may jeopardize the security of your Customer Account.</p>
              <p>4.2.1. If you become aware of, or reasonably suspect that security in your Customer Account has been compromised, including loss, theft or unauthorized disclosure of your password and Customer Account details, you must notify us immediately.</p>
              <p>4.2.2. You are solely responsible for maintaining the confidentiality of your password and you will be held responsible for all uses of your Customer Account, including any purchases made under the Customer Account, whether those purchases were authorized by you or not.</p>
              <p>4.2.3. You are solely responsible for anything that happens through your Customer Account, whether or not you undertake those actions. You acknowledge that your Customer Account may be terminated if someone else uses it and engages in any activity that breaches these Terms and Conditions or is otherwise illegal.</p>
              <p>4.2.4. We are not responsible for any abuse or misuse of your Customer Account by third parties due to your disclosure of your login details to any third party, whether such disclosure is intentional or accidental, active or passive.</p>
              <h4 id='43-account-transfers'>4.3. <strong>Account Transfers</strong></h4>
              <p>You are not allowed to transfer any existing balance between Customer Accounts, or from your Customer Account to other players, or to receive balance from other Customer Accounts into your Customer Account, or to transfer, sell and/or acquire Customer Accounts.</p>
              <h4 id='44-inactive-customer-accounts'>4.4. <strong>Inactive Customer Accounts</strong></h4>
              <p>We reserve the right to deactivate your Customer Account if it is deemed to be an Inactive Account. If no transactions have been recorded on your Customer Account for 12 consecutive months, we reserve the right to zero any balance with no offer of any purchase refunds.</p>
              <h4 id='45-closing-of-customer-accounts'>4.5. <strong>Closing of Customer Accounts</strong></h4>
              <p>If you wish to close your Customer Account, you may do so at any time by selecting the “Contact” link on the Platform and submitting a request to close your Customer Account. Closing your Customer Account will forfeit all continued access to and right to use.</p>
              <p>4.5.0. You will be able to open your Customer Account again by sending a request to the Customer Support team. All requests for the re-opening of an account will be evaluated by our Customer Support and Compliance teams, which shall abide by strict customer protection guidelines.</p>
              <h4 id='46-discretion-to-refuse-or-close-accounts'>4.6. <strong>Discretion to Refuse or Close Accounts</strong></h4>
              <p>We reserve the right to refuse a Customer Account to anyone, or to close an already open Customer Account at our sole discretion. In the event we choose to close an account, depending on the reasoning, any existing balance may be refunded prior to the closure. This is again at our sole discretion.</p>
              <h2 id='5-games'>5. GAMES</h2>
              <h4 id='50-rules'>5.0. <strong>Rules</strong></h4>
              <p>5.0.0. Games offered on the Platform may have their own rules which are available on the Platform. It is your responsibility to read the rules of a Game before playing. You must familiarise yourself with the applicable terms of play and read the relevant rules before playing any Game.</p>
              <p>5.0.1. All crypto exchange transaction fees, charges or related costs that may be incurred as a result of, or in relation to, your balance funding are to be borne solely by you, including but not limited to any losses or additional costs arising from cryptocurrency exchange fluctuations. Balance</p>
              <p>5.0.2. You may participate in any Game only if you have sufficient balance in your Customer Account for such Participation. We will not extend you any credit whatsoever.</p>
              <p>5.0.3. From time to time, we may assign minimum or maximum purchases as specified and offer them on the Platform.</p>
              <p>5.0.4. Once a purchase has been made, the funds will be drawn from your MetaMask account as soon as practicable.</p>
              <p>5.0.5. The deposit of ETH is the purchase of a temporary license granted to You by Us that allows you to Participate in WinBig Games and is not a deposit of funds that can be withdrawn. Funds deposited will not, and cannot, be refunded to you.</p>
              <p>5.0.6. Funds that have been submitted for play and accepted cannot be changed, withdrawn, or canceled and the balance will be drawn instantly.</p>
              <p>5.0.7. <strong>Void Games</strong> We reserve the right to declare Participation in a Game void, partially or in full, if, in our sole discretion, we deem it obvious that there was an error, mistake, misprint or technical error, fraud, or other action invalidating play.</p>
              <p>5.0.8. <strong>Final Decision</strong> In the event of a discrepancy between the result showing on a user’s device and the WinBig Limited server software, the result showing on the WinBig Limited server software will be the official and governing result.</p>
              <p>5.0.9. <strong>WinBig Competitions</strong> WinBig Competitions offered on the platform may have their own entry requirements. It is your responsibility to read the entry requirements and cost prior to entering.</p>
              <p>5.0.10. Competitions operate using a fully audited WEB3-compliant smart contract.</p>
              <p>5.0.10. Competitions are hosted within the smart contract and offer full visibility to the participants, as such, entries can only be made via a MetaMask transaction.</p>
              <p>5.0.11. Entries to WinBig hosted competitions will carry an Ethereum blockchain ‘gas fee’. Gas Fees are a standard when operating on the Ethereum Blockchain.</p>
              <p>5.0.12. All NFT prizes for an active competition are held within the smart contract, and are transferred to the winner once the competition is completed.</p>
              <p>5.0.13. Competition entries cannot be refunded and competition entries will not be refunded if you are not the winner.</p>
              <p>5.0.14. WinBig may implement limitations on the number of entry packages or total entries a single user can buy, this is to ensure fairness between all participants.</p>
              <p>5.0.15. A list of all participants can be found within the smart contract for each competition.</p>
              <p>5.0.16. In the unlikely event of a cancellation, participants can claim their entry cost back from their profile page. Participants can also process a claim-back transaction with the smart contract directly.</p>
              <p>5.0.17. In the unlikely event of a competition cancellation, all unclaimed funds will be transferred to a WinBig wallet after 30 days.</p>
              <p>5.0.18. Failed entries and funds lost as a result of adjusting the recommended gas estimations are not the responsibility of WinBig.</p>
              <p>5.0.19. Competitions with max entries showing will remove entry buttons from the website when the max entry number has been reached. It is possible some further entries reach us after the competition closes by way of delayed receipts or direct smart contract interactions. These entries will be counted. However, any direct smart contract entries will cause the user to be banned from using WinBig further.</p>
              <p>5.0.20. Any evidence of attempts to manipulate the mechanics of Competitions discovered will result in the removal from the Competition draw and the closure of your account. This includes but is not limited to hot-swapping an NFT to access &#39;Holders Only&#39; games.</p>
              <p>5.0.21. Competitions under the ‘Max WinBigners’ category cap the amount of participants who can enter. This max cap is strictly an approximation. When this cap is reached, the UI closes and a call to draw the Competition is sent via the blockchain and is subject to blockchain processing times, the competition will draw shortly thereafter. If multiple new participants are entering during these final moments they may be included in the final draw.</p>
              <p>5.0.22. WinBig reserves the right to amend Competition end times. 5.0.23. Competition max entries account for website entries only.</p>
              <h2 id='6-promotions'>6. PROMOTIONS</h2>
              <p>6.0. All promotions, including contests and special offers, are subject to these Terms and Conditions and to additional terms that may be published at the time of the promotion.</p>
              <p>6.1. In the event and to the extent of any conflict between these Terms and Conditions and any promotion-specific terms and conditions, the promotion-specific terms and conditions will prevail.</p>
              <p>6.2. WinBig reserves the right to withdraw or modify such promotions without prior notice to you.</p>
              <p>6.3. If, in the reasonable opinion of WinBig, we form the view that a Registered Customer is abusing any promotion, to derive any advantage or gain for themselves or other Registered Customers, including by way of Fraudulent Conduct, we may, at our sole discretion, withhold, deny or cancel any advantage, bonus or prize as we see fit.</p>
              <p>6.4. Without limiting clause 9.3., unless restricted by applicable law, you confirm that you grant WinBig Limited an irrevocable, perpetual, worldwide, non-exclusive, royalty-free license to use in whatever way we see fit, and without further acknowledgment of you as the author, any Content you post or publish as part of a promotion, contest or competition.</p>
              <h2 id='7-financial-terms-of-use'>7. FINANCIAL TERMS OF USE</h2>
              <h4 id='70-verification-checks'>7.0. Verification Checks</h4>
              <p>7.0.0. You agree that we are entitled to conduct any identification, credit and other verification checks that we may reasonably require and/or that are required of us under applicable laws and regulations or by relevant regulatory authorities.</p>
              <p>7.0.1. Until all required verification checks are completed to our satisfaction, we are entitled to restrict your Customer Account in any manner that we may reasonably deem appropriate, including by suspending or deactivating your Customer Account.</p>
              <p>7.0.2. Where any identification, credit, or other verification checks we require cannot be completed to our satisfaction because you have not provided any document we request from You in the form that we require within 7 days of the date the document was first requested, we will deactivate your Customer Account. You will not be able to deposit into your balance at WinBig until this verification check has been successfully completed. Credit Checks</p>
              <p>7.0.3. WinBig Limited reserves the right to run external verification checks on all account holders with third-party verification agencies based on the information provided on registration.</p>
              <h4 id='71-expiry-and-forfeiture'>7.1. Expiry and Forfeiture</h4>
              <p>7.1.0. Your WinBig balance is valid for 365 days from the date you last logged on to your Customer Account and will thereafter automatically expire.</p>
              <p>7.1.1. Your WinBig balance may be forfeited if a Customer Account is deactivated for any reason, or at our discretion.</p>
              <h4 id='72-mistaken-credits'>7.2. Mistaken Credits</h4>
              <p>If we mistakenly credit your Customer Account from time to time with additional funds or prizes that do not belong to you, whether due to a technical error, human error or otherwise, the amount credited will remain the property of WinBig Limited and will be deducted from your Customer Account at the earliest opportunity.</p>
              <h2 id='8-fraudulent-conduct'>8. FRAUDULENT CONDUCT</h2>
              <p>8.0. You may not, directly or indirectly:</p>
              <p>8.0.0. Hack into any part of the Games or Platform through password mining, phishing, or any other means;</p>
              <p>8.0.1. Attempt to modify, reverse engineer, or reverse-assemble any part of the Games or Platform;</p>
              <p>8.0.2. Knowingly introduce viruses, Trojans, worms, logic bombs, spyware, malware, or other similar material;</p>
              <p>8.0.3. Circumvent the structure, presentation or navigational function of any Game so as to obtain information that is not publicly available on the Platform;</p>
              <p>8.0.4. Engage in any form of cheating or collusion;</p>
              <p>8.0.5. Use the Services and the systems of WinBig Limited to facilitate any type of illegal money transfer (including money laundering proceeds of crime); or</p>
              <p>8.0.6. Participate in or take advantage of, or encourage others to participate in or take advantage of schemes, organizations, agreements, or groups designed to share:</p>
              <p>8.0.6.0. Special offers or packages emailed to a specific set of players and redeemable by URL;</p>
              <p>8.0.6.1. Identification documents (including, but not limited to, photographs, bills and lease documents) for the purpose of misleading WinBig Limited as to a Player’s identity.</p>
              <p>8.1. You must not use the Platform for any unlawful or fraudulent activity or prohibited transaction (including Fraudulent Conduct) under the laws of any jurisdiction that applies to you. We monitor all transactions in order to prevent money laundering.</p>
              <p>8.2. If WinBig Limited suspects that you may be engaging in, or have engaged in fraudulent, unlawful or improper activity, including money laundering activities or any conduct which violates these Terms and Conditions, your access to the Services will be deactivated immediately and your Customer Account may be suspended. If your Customer Account is deactivated or suspended under such circumstances, WinBig Limited is under no obligation to reverse any purchases you have made. In addition, WinBig Limited may pass any necessary information on to the relevant authorities, other online service providers, banks, credit card companies, electronic payment providers or other financial institutions. You will cooperate fully with any WinBig Limited investigation into such activity.</p>
              <p>8.3. If you suspect any unlawful or fraudulent activity or prohibited transaction by another Player, please notify us immediately via the means of communication listed in the Customer Complaints procedure (described in clause 15.).</p>
              <h2 id='9-intellectual-property'>9. INTELLECTUAL PROPERTY</h2>
              <p>9.0. The computer software, the computer graphics, the Platform and the user interface that we make available to you are owned by, or licensed to, WinBig Limited or its associates and protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, terms and conditions we have established and in accordance with all applicable laws, rules and regulations.</p>
              <p>9.1. You acknowledge that WinBig Limited is the proprietor or authorized licensee of all intellectual property in relation to any Content.</p>
              <p>9.2. Your use of the Games and Platform does not provide you with any intellectual property rights in the Content, Games or Platform.</p>
              <p>9.3. You grant Us, and represent and warrant that you have the right to grant us, an irrevocable, perpetual, worldwide, non-exclusive, royalty-free license to use in whatever way we see fit, any information, images, videos, comments, messages, music or profiles you publish or upload to any website or social media page controlled and operated by WinBig Limited.</p>
              <p>9.4. You must not reproduce or modify the Content in any way, including by removing any copyright or trademark notice.</p>
              <p>9.5. All trademarks and logos displayed in the Games and Platform are the property of their respective owners and are protected by applicable trademark and copyright laws.</p>
              <h2 id='10-third-party-websites-and-links'>10. THIRD-PARTY WEBSITES AND LINKS</h2>
              <p>10.0. You acknowledge and agree that WinBig:</p>
              <p>10.0.0. Is not responsible for Third Party Websites; and</p>
              <p>10.0.1. Makes no guarantee as to the content, functionality, or accuracy of any Third Party Website.</p>
              <p>10.1. You further acknowledge that some Third Party Websites may be fraudulent in nature, offering rewards that the operators of those websites are not authorized to provide, in an effort to induce you to reveal personal information (including passwords, account information, and credit card details). You agree that WinBig Limited is not responsible for any actions you take at the request or direction of these, or any other Third Party Websites.</p>
              <p>10.2. Third Party Websites are subject to the terms and conditions outlined by that third party.</p>
              <p>10.3. Any links to Third Party Websites do not:</p>
              <p>10.3.0. Indicate a relationship between WinBig Limited and the third party; or</p>
              <p>10.3.1. Indicate any endorsement or sponsorship by WinBig Limited of the Third Party Website, or the goods or services it provides, unless specifically indicated by WinBig Limited.</p>
              <p>10.4. Where a website controlled and operated by WinBig Limited contains links to various social networking sites, such as Facebook®, Instagram®, Discord® and Twitter®, you acknowledge and agree that:</p>
              <p>10.4.0. Any comments or content that you post on such social networking sites are subject to the terms and conditions of that particular social networking site;</p>
              <p>10.4.1. You will not post any comments that are false, misleading or deceptive or defamatory to us, our employees, agents, officers or other players; and</p>
              <p>10.4.2. We are not responsible or liable for any comments or content that you or others post on social networking sites.</p>
              <h2 id='11-disruptions-and-change'>11. DISRUPTIONS AND CHANGE</h2>
              <h4 id='110-no-warranties'>11.0. No warranties</h4>
              <p>The Platform is provided on an “as is” basis and to the fullest extent permitted by law, we make no warranty or representation, whether express or implied, in relation to the satisfactory quality, fitness for purpose, completeness or accuracy of the Platform (including the Games and Content).</p>
              <h4 id='111-malfunctions'>11.1. Malfunctions</h4>
              <p>11.1.0. WinBig Limited is not liable for any downtime, server disruptions, lagging, or any technical or political disturbance to Gameplay, nor attempts by you to Participate by methods, means or ways not intended by us.</p>
              <p>11.1.1. WinBig Limited accepts no liability for any damages or losses which are deemed or alleged to have arisen out of or in connection with any Platform or its content including, without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of a Platform or its content or any errors or omissions in content.</p>
              <p>11.1.2. In the event of a Platform system malfunction all Game play on that Platform is void.</p>
              <p>11.1.3. In the event a Game is started but fails to conclude because of a failure of the system, WinBig Limited will use commercially reasonable methods to reinstate the amount of funds played in the Game to you by crediting it to your Customer Account. WinBig Limited reserves the right to alter Player balances and account details to correct such mistakes.</p>
              <p>11.1.4. WinBig Limited reserves the right to remove any part of the Games from the Platform at any time. Any part of the Games that indicate incorrect behavior affecting game data, balances, that may be due to configuration or a bug, will be canceled and removed from the Platform. Player balances and account details may be altered by WinBig Limited in such cases in order to correct any mistake.</p>
              <p>11.1.5. WinBig Limited reserves the right to suspend, modify, remove or add Content to the Platform at its sole discretion with immediate effect and without notice to you. We will not be liable to you for any loss suffered as a result of any changes made or for any modification or suspension of or discontinuance of the Platform (including any game thereon) and you will have no claims against WinBig Limited in such regard.</p>
              <h4 id='112-service-suspension'>11.2. Service Suspension</h4>
              <p>We may temporarily suspend the whole or any part of the Platform for any reason at our sole discretion. We may, but will not be obliged to, give you notice of such suspension. We will restore the Platform, as soon as is practicable in our sole discretion, after such temporary suspension.</p>
              <h4 id='113-changes-to-jurisdiction-regulation'>11.3. Changes to Jurisdiction Regulation</h4>
              <p>The Company monitors legal changes taking place across the world. In the event of any proposed legal change in any region, we reserve the right to immediately suspend all access to the Website for any user playing from where the proposed changes are occurring. In the event of such a change, all funds shall be void with no financial compensation offered.</p>
              <h2 id='12-viruses'>12. VIRUSES</h2>
              <p>Although we take all reasonable measures to ensure that the Platform is free from computer viruses we cannot and do not guarantee that the Platform is free of such problems. It is your responsibility to protect your systems and have in place the ability to reinstall any data or programs lost due to a virus.</p>
              <h2 id='13-privacy-policy'>13. PRIVACY POLICY</h2>
              <p>13.0. WinBig Limited is committed to protecting and respecting your privacy and complying with all applicable data protection and privacy laws.</p>
              <p>13.1. Our Privacy Policy is inseparably linked to these Terms and Conditions and its acceptance is a prerequisite to account registration.</p>
              <p>13.2. If you have given consent when registering to receive marketing communications from WinBig Limited in respect of its offerings by way of email, post, SMS, and telephone notifications, you may unsubscribe from them at any time by contacting Customer Support via email at feedback@winbig.space. alternatively, you may use the contact preferences facility available online.</p>
              <h2 id='14-complaints-and-customer-support'>14. COMPLAINTS AND CUSTOMER SUPPORT</h2>
              <p>14.0. If you would like to contact our Customer Support department or have a complaint regarding our Platform (including any game), you may contact us via email at feedback@winbig.space</p>
              <p>14.1. TO PROTECT YOUR PRIVACY, ALL EMAIL COMMUNICATIONS BETWEEN YOU AND WinBig LIMITED SHOULD BE CARRIED OUT USING THE EMAIL ADDRESS THAT YOU HAVE REGISTERED AGAINST YOUR CUSTOMER ACCOUNT HELD WITH WinBig LIMITED. FAILURE TO DO SO MAY RESULT IN OUR RESPONSE BEING DELAYED.</p>
              <p>14.2. The following information must be included in any written communication with WinBig Limited (including a complaint):</p>
              <p>14.2.0. your username;</p>
              <p>14.2.1. your first and last name, as registered on your Customer Account;</p>
              <p>14.2.2. a detailed explanation of the complaint/claim; and</p>
              <p>14.2.3. Any specific dates and times associated with the complaint/claim (if applicable).</p>
              <p>14.3. Failure to submit a written communication with the information outlined above may result in a delay in our ability to identify and respond to your complaint/claim in a timely manner. The Customer Support department will investigate official complaints immediately. The Customer Support department will endeavor to respond to official complaints within 5 calendar days of lodgement.</p>
              <p>14.4. In some circumstances, the Customer Support department will require up to 10 days to respond to a complaint. In this case, the player will be informed of the delay within 3 days of lodging the complaint.</p>
              <h2 id='15-deactivation--suspension-of-account'>15. DEACTIVATION / SUSPENSION OF ACCOUNT</h2>
              <p>15.0. WinBig Limited hereby reserves the right to deactivate or suspend your Customer Account for any reason whatsoever at any time without notifying you.</p>
              <p>15.1. Without limiting clause 17.1., we hereby reserve the right, at our sole discretion, to deactivate or suspend your Customer Account (notwithstanding any other provision contained in these Terms and Conditions) where we have reason to believe that you have engaged or are likely to engage in any of the following activities:</p>
              <p>15.1.0. You breached, or assisted another party to breach, any provision of these Terms and Conditions, or we have a reasonable ground to suspect such breach;</p>
              <p>15.1.1. You have more than one Customer Account, including any Inactive Account, on any Platform;</p>
              <p>15.1.2. The details registered on your Customer Account do not match the details on the financial/bank account and/or the credit/debit card(s) used to make purchases on the said Customer Account;</p>
              <p>15.1.3. Your communication with us consists of harassment or offensive behavior, including (but not limited to) threatening, derogatory, abusive or defamatory statements, or racist, sexually explicit, pornographic, obscene, or offensive language;</p>
              <p>15.1.4. Your Customer Account is deemed to be an Inactive Account;</p>
              <p>15.1.5. You become bankrupt;</p>
              <p>15.1.6. You provide incorrect or misleading information while registering a Customer Account;</p>
              <p>15.1.7. Your identity cannot be verified;</p>
              <p>15.1.8. You attempt to use your Customer Account through a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your citizenship, location or place of residence, or by playing Games using the Platform through a third party or on behalf of a third party;</p>
              <p>15.1.9. You are not over 18 years of age;</p>
              <p>15.1.10. You are located in a jurisdiction where Participation is illegal;</p>
              <p>15.1.11. You have allowed or permitted (whether intentionally or unintentionally) someone else to Participate using your Customer Account;</p>
              <p>15.1.12. You have played in tandem with other Player(s) as part of a club, group, etc., or playing the Games in a coordinated manner with other Player(s) involving the same (or materially the same) selections;</p>
              <p>15.1.13. You have failed our due diligence procedures, or are found to be colluding, cheating, money laundering or undertaking any kind of fraudulent activity; or</p>
              <p>15.1.14. It is determined by WinBig Limited that you have employed or made use of a system (including machines, computers, software or other automated systems such as bots) designed specifically to gain an unfair advantage.</p>
              <p>15.1.15. Where WinBig Limited determines that it is under a legal obligation or to protect WinBig Limited, you, other players, or other third parties.</p>
              <p>15.1.16. If WinBig Limited deactivates or suspends your Customer Account for any of the reasons referred to in clause 16.1. above, you will be liable for any and all claims, losses, liabilities, damages, costs, and expenses incurred or suffered by WinBig Limited (together “Claims”) arising therefrom and you will indemnify and hold WinBig harmless on demand for such Claims.</p>
              <p>15.1.17. If we have reasonable grounds to believe that you have participated in any of the activities set out in clause 16.1. above then we reserve the right to withhold all or part of the balance and/or recover from your Customer Account any funds that are attributable to any of the activities contemplated in clause 16.1. In such circumstances, your details may be passed on to any applicable regulatory authority, regulatory body or any other relevant external third parties.</p>
              <p>15.1.18. If your Customer Account is deactivated as a result of the closure of the Platform or a similar event, any temporary licenses granted to you shall immediately be terminated and no refunds for purchases shall be due.</p>
              <p>15.1.19. The rights set out in clause 16. are without prejudice to any other rights that we may have against you under these Terms and Conditions or otherwise.</p>
              <h2 id='16-indemnity-and-limitation-of-liability'>16. INDEMNITY AND LIMITATION OF LIABILITY</h2>
              <p>16.0. Indemnity You hereby agree to indemnify and hold harmless us, our directors, officers, employees, shareholders, agents and affiliates, our ultimate parent and parent companies and any of our subsidiaries against any and all costs, expenses, liabilities and damages (whether direct, indirect, special, consequential, exemplary or punitive or other, including attorney’s fees arising from any participation by you, including without limitation:</p>
              <p>16.0.0. Accessing or using the platform;</p>
              <p>16.0.1. Re-use of any content at, or obtained from, the platforms or any other source whatsoever;</p>
              <p>16.0.2. Facilitating or making a payment into your customer account; d. Playing the games through any delivery mechanism offered; and</p>
              <p>16.0.3. Acceptance and use of any prize.</p>
              <p>16.1. Limitation of Liability</p>
              <p>16.1.0. To the maximum extent permitted by applicable law, under no circumstances whatever will we or our affiliates, subsidiaries, partners, officers, directors, employees, shareholders, agents, licensors, subcontractors and suppliers, be responsible or liable to you or to any other entity, even if advised of the possibility of such damages, under any legal theory, whether contract, tort or otherwise, for any indirect, incidental, consequential, special, exemplary, or punitive damages, including any lost profits and lost business opportunities, business interruption, lost revenue, income, goodwill, use of data or other intangible losses, in each case that result from or relate in any manner to your participation or any other act or omission by us.</p>
              <p>16.1.1. To the fullest extent permitted by applicable law, under no circumstances will we, our affiliates, subsidiaries, partners, officers, directors, employees, shareholders, agents, licensors, subcontractors or suppliers, be liable to you for more than the amount you have paid us in the thirty (30) days immediately preceding the date on which you first assert any such claim. You acknowledge and agree that if you have not paid us any amounts in the thirty (30) days immediately preceding the date on which you first assert any such claim, your sole and exclusive remedy for any dispute with us is to stop using the platform and to close your Customer Account.</p>
              <p>16.1.2. You recognize and agree that the warranty disclaimers in clauses 11. and 11.2., and the indemnities and limitations of liability in this clause 17., are material and bargained-for bases of these terms and that they have been taken into account and reflected in the decision by you to enter into these terms and conditions. Depending on where you reside and use the Platform, some of the limitations contained in clause 17 may not be permissible. In such case, they will not apply to you, solely to the extent that they are prohibited.</p>
              <p>16.2. Negligence and Wilful Misconduct Nothing in these Terms and Conditions will operate so as to exclude any liability of WinBig for death or personal physical injury that is directly and proximately caused by WinBig’s gross negligence or wilful misconduct.</p>
              <p>16.3. Survival of Obligations Clause 17. survives the termination of these Terms and Conditions for any reason.</p>
              <h2 id='17-winbig-limited-not-a-financial-institution'>17. WinBig LIMITED NOT A FINANCIAL INSTITUTION</h2>
              <h4 id='170-interest'>17.0. Interest</h4>
              <p>You will not receive any interest in your balance and you will not treat WinBig as a financial institution.</p>
              <h4 id='171-no-legal-or-tax-advice'>17.1. No legal or tax advice</h4>
              <p>WinBig does not provide advice regarding tax and/or legal matters. Players who wish to obtain advice regarding tax and legal matters are advised to contact appropriate advisors. Players are responsible for any and all tax liability from the use of the Website.</p>
              <h2 id='18-dispute-resolution-and-agreement-to-arbitrate'>18. DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE</h2>
              <p>Please read this clause 19. carefully as it requires you to arbitrate certain disputes and claims on an individual basis and limits the manner in which you can seek relief from WinBig. This clause 19. will be construed under and be subject to the Federal Arbitration Act, notwithstanding any other choice of law set out in these Terms and Conditions.</p>
              <p>18.0. By agreeing to these Terms and Conditions, you agree that any and all past, present and future disputes, claims or causes of action between you and WinBig which arise out of, or are related to, these Terms and Conditions, the formation of these Terms and Conditions, the validity or scope of these Terms and Conditions, including this clause 19., your participation or other access to or use of the Platform, or any other dispute between You and WinBig Limited, and whether arising prior to or after your agreement to this clause 19. (Dispute Resolution and Agreement to Arbitrate) (collectively, “Disputes”), will be governed by the procedure set out below.</p>
              <h4 id='181-complaint-resolution'>18.1. Complaint Resolution</h4>
              <p>18.1.0. We want to address any concerns you may have without needing a formal legal case.</p>
              <p>18.1.1. Before filing a claim against WinBig Limited, you agree to try to resolve any complaint in accordance with clause 15.. If your complaint is not resolved after exhausting the internal complaints process outlined in clause 15., you may initiate Dispute resolution as set out in this clause 18.</p>
              <p>18.1.2. WinBig Limited agrees that it will take all reasonable efforts to contact you and resolve any claim it may possess informally prior to taking any formal action.</p>
              <h4 id='182-arbitration'>18.2. Arbitration</h4>
              <p>18.2.0. We Both Agree to Arbitrate. By agreeing to these Terms and Conditions, both You and WinBig Limited agree to resolve any Disputes through final and binding arbitration.</p>
              <p>18.2.1. Opt-out of Agreement to Arbitrate. You may decline this agreement to arbitrate by contacting feedback@winbig.space with the subject line ARBITRATION OPT-OUT within 30 days of first accepting these Terms and Conditions. Your email must include your first and last name and a statement that you decline this arbitration clause. By opting out of this clause, you will not be precluded from using the Platform, but neither You nor WinBig Limited will be able to invoke the mutual agreement to arbitrate to resolve Disputes. Whether to agree to arbitration is an important decision. It is your decision to make and you are not required to rely solely on the information provided in these Terms and Conditions. You should take reasonable steps to conduct further research and, if you wish, to consult with the counsel of your choice.</p>
              <p>18.2.2. Arbitration Procedures and Fees You and WinBig Limited agree that:</p>
              <p>18.2.2.0. The American Arbitration Association (AAA) will administer the arbitration under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes, or successor rules, which are in effect at the time arbitration is sought (the AAA Rules). Those rules are available at <a href='http://www.adr.org' target='_blank' rel="noreferrer">www.adr.org</a>;</p>
              <p>18.2.2.1. Arbitration will proceed on an individual basis;</p>
              <p>18.2.2.2. Arbitration will be handled by a sole arbitrator in accordance with the AAA Rules;</p>
              <p>18.2.2.3. The AAA rules will govern payment of all arbitration fees; and</p>
              <p>18.2.2.4. Except as otherwise may be required by the AAA Rules, the arbitration will be held in New York City, NY, USA or, at your election, conducted via telephone or other remote electronic means.</p>
              <p><em>18.2.3.</em> Arbitration to Proceed Individually</p>
              <p>18.2.3.0. You and WinBig agree that the arbitration of a Dispute will proceed on an individual basis and neither you nor WinBig may bring a claim as a Collective Action.</p>
              <p>18.2.3.1. Without limiting the generality of clause 19.2. and as an example only, a claim to resolve a Dispute against WinBig Limited will be deemed a Collective Arbitration if:</p>
              <p>18.2.3.1.0. Two or more similar claims for arbitration are filed concurrently by or on behalf of one or more person; and</p>
              <p>18.2.3.1.1. Counsel for the two or more persons is the same, or share fees, or coordinate in any way across the arbitrations.</p>
              <p>18.2.3.1.2. For the purposes of clause 19.2., the term ‘concurrently’ means that both arbitrations are pending (filed but not resolved) at the same time.</p>
              <h4 id='1824-waiver-of-class-action-and-collective-arbitration'>18.2.4. Waiver of Class Action and Collective Arbitration</h4>
              <p>To the maximum extent permitted by applicable law, whether the dispute is heard in arbitration or in court (i.e, in the event you have opted out of arbitration), neither You nor WinBig Limited will be entitled to consolidate, join or coordinate disputes with or involving other individuals or entities, or participate in any collective arbitration (as defined above) or arbitrate or litigate any dispute in a representative capacity, including as a representative member of a class or in a private attorney general capacity. In connection with any dispute (as defined above), all such rights are expressly and unconditionally waived. Notwithstanding anything to the contrary in these Terms and Conditions, in the event all or any portion of clauses 19.2. or 20.8<a href='https://metawin.com/terms-and-conditions#smartreference=ulud8jc4yzr4'>.</a> are found to be invalid or less than fully enforceable, then the entirety of this clause 19. may be deemed void and as having no effect upon either your or our election.</p>
              <h2 id='19-other'>19. OTHER</h2>
              <h4 id='190-entire-agreement'>19.0. Entire Agreement</h4>
              <p>These Terms and Conditions constitute the entire agreement between You and Us with respect to your Participation and, save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between You and Us with respect to your Participation.</p>
              <h4 id='191-amendments'>19.1. Amendments</h4>
              <p>19.1.0. WinBig Limited hereby reserves the right to amend these Terms and Conditions, or to implement or amend any procedures, at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately.</p>
              <p>19.1.1. Whenever we amend these Terms and Conditions in a way that would limit your current rights or which may be to your detriment, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended Terms and Conditions, you must stop using the Platform.</p>
              <h4 id='192-tax'>19.2. Tax</h4>
              <p>You are solely responsible for any taxes applicable from your Participation.</p>
              <h4 id='193-force-majeure'>19.3. Force Majeure</h4>
              <p>WinBig Limited will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms and Conditions that are caused by events outside of our reasonable control.</p>
              <h4 id='194-no-agency'>19.4. No agency</h4>
              <p>Nothing in these Terms and Conditions will be construed as creating any agency, partnership, trust arrangement, fiduciary relationship or any other form of joint enterprise between You and Us.</p>
              <h4 id='195-severability'>19.5. Severability</h4>
              <p>If any of the Terms and Conditions are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will, to that extent, be severed from these Terms and Conditions. All remaining terms, conditions and provisions will continue to be valid to the fullest extent permitted by law. In such cases, the part deemed invalid or unenforceable will be amended in a manner consistent with the applicable law to reflect, as closely as possible, WinBig Limited’s original intent.</p>
              <h4 id='196-explanation-of-terms-and-conditions'>19.6. Explanation of Terms and Conditions</h4>
              <p>19.6.0. We consider these Terms and Conditions to be open and fair. If you need any explanation regarding these Terms and Conditions or any other part of our Platform contact Customer Support via email at feedback@winbig.space.</p>
              <p>19.6.1. The Terms and Conditions prevail over any communication via email or chat.</p>
              <p>19.6.2. All correspondence, telephone or video conferences, and live chats between You and Us may be recorded and retained and you consent to such recording and retention.</p>
              <h4 id='197-assignment'>19.7. Assignment</h4>
              <p>These Terms and Conditions are personal to You, and are not assignable, transferable or sub-licensable by You except with Our prior written consent. We reserve the right to assign, transfer or delegate any of Our rights and obligations hereunder to any third party without notice to You.</p>
              <h4 id='198-business-transfers'>19.8. Business Transfers</h4>
              <p>In the event of a change of control, merger, acquisition, or sale of assets of WinBig Limited, your Customer Account and associated data may be part of the assets transferred to the purchaser or acquiring party. In such an event, we will provide You with notice via email or via Our Platform explaining your options with regard to the transfer of your Customer Account.</p>
              <h4 id='199-language'>19.9. Language</h4>
              <p>These Terms and Conditions may be published in several languages for information purposes and ease of access by players but will all reflect the same principles. It is only the English version that is the legal basis of the relationship between You and Us and in case of any discrepancy between a non-English version and the English version of these Terms and Conditions, the English version will prevail.</p>
              <h4 id='1910-applicable-law-and-jurisdiction'>19.10. Applicable Law and Jurisdiction</h4>
              <p>19.10.0. These Terms and Conditions will be governed, and interpreted in accordance with, the Laws of Gibraltar, without regard for its choice or conflict of law principles.</p>
              <p>19.10.1. You acknowledge that, unless stated otherwise, the Games are operated from the Isle of Man and your participation takes place within the aforementioned territory. Any contractual relationship between You and Us will be deemed to have been entered into and performed by the parties.</p>
              <p>19.10.2. The parties agree that any dispute, controversy or claim arising out of or in connection with these Terms and Conditions, or the breach, termination or invalidity thereof, that is not expressly subject to arbitration, will be submitted exclusively to the courts in Gibraltar, and You and We consent to the venue and personal jurisdiction of those courts. Notwithstanding the foregoing, any motion to compel arbitration or to enforce an arbitral award issued hereunder may be brought before any court of competent jurisdiction.</p>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default TermsAndConditions;